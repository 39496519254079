<template>
  <div class="container" fluid="lg">
    <div class="row justify-content-center">
      <div class="col-12 bg-secondary-light py-4 px-2">
        <div class="text-center">
          <h6>{{ $t('please_upload_documents') }}</h6>
          <h6 class="mt-4" v-html="$t('need_upload_documents', { image: documentRequestNameList })"></h6>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="row">
          <div class="col-12 p-0 text-center">
            <div v-if="formData">
              <FormRenderer v-model="formInputData" :form-configuration="formData" />

              <b-button pill variant="primary px-4 my-3" @click="submitUpdateDocuemnt()">{{
                $t('reupload_request')
              }}</b-button>
            </div>
            <div v-else class="d-flex justify-content-center">
              <h6 class="text-danger">{{ error_message }}</h6>
            </div>
          </div>
        </div>
        <loading :is-busy="isBusy"></loading>
      </div>
    </div>
  </div>
</template>

<script>
import { FormRenderer } from 'v-form-builder'
import FormService from '@/api/service/FormService'
import LoanService from '@/api/service/LoanService'
import Loading from '@/components/Loading.vue'

import '@/assets/scss/v-form-builder.scss'

import formDataMixin from '@/mixins/formDataMixin'

import { bus } from '@/main.js'

export default {
  components: { FormRenderer, Loading },
  mixins: [formDataMixin],
  data() {
    return {
      formData: null,
      formInputData: null,
      getInputData: null,
      isBusy: false,
      first_merge: true,
      error_message: null,
      loanApplication: null,
      status: '',
      documents_request: null,
      documentRequestControlIdList: [],
      loanApplicationId: '',
      version_id: '',
    }
  },
  computed: {
    documentRequestNameList() {
      var document = []
      if (this.documents_request) {
        this.documents_request.forEach((doc) => {
          if (doc.status === true) {
            if (this.$i18n.locale == 'en') {
              document.push(doc.name_en)
            } else if (this.$i18n.locale == 'th') {
              document.push(doc.name_th)
            } else {
              document.push(doc.name_mm)
            }
          }
        })
      }
      return document.join()
    },
  },
  beforeCreate() {
    this.$formEvent.$off()
    this.$formEvent.$on()
  },
  created() {
    // this.getFormData();
    this.getLoanApplication()

    // this.$formEvent.$on("confirm", (value) => {
    //   console.log(value);
    //   this.saveInputData();
    // });

    bus.$on('uploadDocument', (data) => {
      if (data === 'uploaded') {
        this.getDocumentList()
      }
    })
  },
  methods: {
    async getLoanApplication() {
      try {
        this.isBusy = true
        const res = await LoanService.loanApplication()
        this.isBusy = false
        if (res.status == 200) {
          const loanResponse = res.data.data
          this.loanApplication = loanResponse
          if (this.loanApplication) {
            this.status = loanResponse.status
            this.loanApplicationId = loanResponse.id
            this.documents_request = loanResponse.documents_request
            if (this.documents_request) {
              this.getFormData()
            }
          }
        }
      } catch (err) {
        this.isBusy = false
        this.showAlert(err)
      }
    },
    async getFormData() {
      this.isBusy = true
      try {
        const res = await FormService.getFormData()
        this.isBusy = false
        if (res.status == 200) {
          const formResponseData = res.data.data
          if (formResponseData.input_data) {
            this.getInputData = JSON.parse(formResponseData.input_data.input_data)
          }
          if (formResponseData.pages) {
            formResponseData.pages.forEach((page) => {
              if (page.type === 'documents') {
                //nedd to modify control id list
                this.documents_request.forEach((document) => {
                  if (document.status === true) {
                    this.documentRequestControlIdList.push(document.control_id)
                  }
                })
                this.formData = this.bindLocale(JSON.parse(page.form_data), true, this.documentRequestControlIdList)
                this.version_id = page.version_id

                this.getDocumentList()
              }
            })
          }
          /**
           * After finish api call, added javascript click event
           */
          this.addClickEvent()
          this.removeZero()
        }
      } catch (err) {
        this.isBusy = false
        this.error_message = err
        this.showAlert(err)
      }
    },
    async getDocumentList() {
      try {
        this.isBusy = true
        const resDocument = await FormService.getDocumentFormData({
          version_id: this.version_id,
        })

        this.isBusy = false

        if (resDocument.status == 200) {
          const uploadedResponse = resDocument.data.data
          this.$store.dispatch('form/saveUploadedDocument', {
            uploadedDocumentList: uploadedResponse,
          })
        }
      } catch (err) {
        this.isBusy = false
        this.showAlert(err)
      }
    },

    async submitUpdateDocuemnt() {
      this.isBusy = true
      try {
        const res = await FormService.submitUpdateDocuemnt({
          loan_application_id: this.loanApplicationId,
        })

        this.isBusy = false

        if (res.status == 200) {
          this.$router.push({ path: 'home' })
        }
      } catch (err) {
        this.isBusy = false
        this.showAlert(err)
      }
    },
  },
}
</script>

<style></style>
